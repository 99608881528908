<template>
	<div>
		<pui-datatable
			:modelName="modelName"
			:actions="actions"
			:modelColumnDefs="modelColumnDefs"
		></pui-datatable>
		<mtipomanipulacion-modals
			:modelName="modelName"
		></mtipomanipulacion-modals>
	</div>
</template>

<script>
import mtipomanipulacionActions from './MtipomanipulacionActions';
import mtipomanipulacionModals from './MtipomanipulacionModals.vue';

export default {
	name: 'mtipomanipulacion-grid',
	components: {
		'mtipomanipulacion-modals': mtipomanipulacionModals
	},
	data() {
		return {
			modelName: 'mtipomanipulacion',
			actions: mtipomanipulacionActions.gridactions,
			modelColumnDefs: {
			}
		}
	}
}
</script>
